import { decrypt } from 'lib/restrictedRoutes/encryption';
import { retrieveWhetherRouteIsRestricted } from 'lib/restrictedRoutes/restrictedRouteRequests';
import { removeRouteLocale } from 'lib/restrictedRoutes/routes';
import { GetServerSideProps } from 'next';

const ROUTE = 'restricted-route';
const UNRESTRICTED_ROUTES = ['/', '/emptybag'];
export const UNRESTRICTED_COOKIE = 'unrestrictedRoutes';

const checkIfRestrictedRoute =
  <T extends Record<string, unknown>>(
    getServerSideProps: GetServerSideProps<T>
  ): GetServerSideProps<T> =>
  async args => {
    const {
      query,
      req: { cookies },
      resolvedUrl,
    } = args;

    const uri = removeRouteLocale(resolvedUrl);

    if (UNRESTRICTED_ROUTES.includes(uri)) {
      return await getServerSideProps(args);
    }

    const isRestricted = await retrieveWhetherRouteIsRestricted(uri);

    if (!isRestricted) {
      return await getServerSideProps(args);
    }

    const hasPermission = decrypt<string[]>(
      cookies[UNRESTRICTED_COOKIE]
    )?.includes(uri);

    if (!hasPermission) {
      return {
        redirect: {
          statusCode: 307,
          destination: `/${query.locale}/${ROUTE}?referrer=${encodeURIComponent(
            resolvedUrl
          )}`,
        },
      };
    }

    return await getServerSideProps({
      ...args,
      params: { ...args.params, doNotCache: 'true' },
    });
  };

export default checkIfRestrictedRoute;
