import { createCipheriv, createDecipheriv } from 'crypto';

const algorithm = 'aes-128-ecb';
const key = Buffer.from('EtaOVH1KVvM8G9lq');
const iv = Buffer.alloc(0);
const inputEncoding = 'utf8';
const outputEncoding = 'base64';

export const encrypt = <T extends object>(data: T): string => {
  const clear = JSON.stringify(data);

  const cipher = createCipheriv(algorithm, key, iv);

  return `${cipher.update(clear, inputEncoding, outputEncoding)}${cipher.final(
    outputEncoding
  )}`;
};

export const decrypt = <T extends object>(data?: string): T | null => {
  if (!data) {
    return null;
  }

  const decipher = createDecipheriv(algorithm, key, iv);

  try {
    const deciphered = `${decipher.update(
      data,
      outputEncoding,
      inputEncoding
    )}${decipher.final(inputEncoding)}`;

    return JSON.parse(deciphered);
  } catch (e) {
    return null;
  }
};
