import CampaignForm from 'components/CampaignForm';
import {
  defaultOnSubmitCampaignForm,
  formatCampaignCheckboxValue,
  parseCampaignCheckboxValue,
} from 'components/CampaignForm/CampaignForm';
import Gap from 'components/Gap';
import { CheckboxInput } from 'components/Input';
import { FC, useCallback } from 'react';
import { Field } from 'react-final-form';
import { useDispatch, useSelector } from 'store/hooks';

interface AgsFormProps {
  visible?: boolean;
}

const AgsForm: FC<AgsFormProps> = ({ visible }) => {
  const dispatch = useDispatch();

  const ue = useSelector(state => state.customer.email);

  const onSubmit = useCallback(defaultOnSubmitCampaignForm(dispatch), [
    dispatch,
  ]);

  if (!visible) {
    return null;
  }

  return (
    <>
      <Gap size={{ xs: 64, l: 96 }} />
      <CampaignForm
        initialValues={{ ue, pm_form: 'ags_competition_may22', ags_optin: '0' }}
        inline={true}
        onSubmit={onSubmit}
        showNameFields={true}
      >
        <Field<string, HTMLInputElement, boolean>
          name="ags_optin"
          type="checkbox"
          parse={parseCampaignCheckboxValue}
          format={formatCampaignCheckboxValue}
        >
          {({ input, meta }) => (
            <CheckboxInput
              value="1"
              checked={input.checked}
              error={meta.error}
              label="I would like to subscribe to the Ancient Greek Sandals email updates and receive the latest news and exclusive offers"
              name={input.name}
              onChange={input.onChange}
              showError={meta.touched}
            />
          )}
        </Field>
      </CampaignForm>
    </>
  );
};

export default AgsForm;
