import { ProductProjection } from '@commercetools/platform-sdk';
import {
  IContentPage,
  IOutfit,
  IProductGrid,
  IProductMosaic,
  IProductRail,
} from 'generated/contentful';
import searchByCategories from 'lib/commerceTools/searchByCategories';
import searchBySkus from 'lib/commerceTools/searchBySkus';
import deriveLocalisedContentItem from 'lib/contentful/deriveLocalisedContentItem';
import fetchResource from 'lib/contentful/fetchResource';
import translate from 'lib/translate';

interface FetchContentfulContentPageArgs {
  locale: string;
  slug: string;
}

export interface ContentPageResource {
  contentPage: IContentPage;
  products: Record<string, ProductProjection>;
  productsByCategory: Record<string, string[]>;
}

export const NOT_FOUND = 'NOT_FOUND';

export const assignProductKeysToCategories = (
  products: Record<string, ProductProjection>,
  locale: string
): Record<string, string[]> => {
  const t = translate(locale.split('-')[0]);

  const productsByCategory: Record<
    string,
    { key: string; name: string; order: number }[]
  > = {};

  const productKeysByCategory: Record<string, string[]> = {};

  for (const key in products) {
    const product = products[key];

    for (const category of product.categories) {
      const categoryKey = category.obj?.key as string;

      const order = product.categoryOrderHints?.[category.id]
        ? parseFloat(product.categoryOrderHints?.[category.id])
        : 1;

      if (!productsByCategory[categoryKey]) {
        productsByCategory[categoryKey] = [];
      }

      productsByCategory[categoryKey].push({
        key,
        name: t(product.name),
        order,
      });
    }
  }

  for (const categoryKey in productsByCategory) {
    productKeysByCategory[categoryKey] = productsByCategory[categoryKey]
      .slice()
      .sort((a, b) => {
        if (a.order < b.order) {
          return -1;
        }

        if (a.order > b.order) {
          return 1;
        }

        return a.name.localeCompare(b.name);
      })
      .map(product => product.key);
  }

  return productKeysByCategory;
};

const fetchContentAndProducts = async ({
  locale,
  slug,
}: FetchContentfulContentPageArgs): Promise<ContentPageResource> => {
  const contentPages = await fetchResource<IContentPage>({
    type: 'contentPage',
    limit: 2,
    query: {
      locale,
      slug,
    },
  });

  const contentPage = deriveLocalisedContentItem(contentPages, locale);

  if (!contentPage?.fields) {
    throw new Error(`${NOT_FOUND}: ${slug}`);
  }

  if (!contentPage.fields.slots) {
    return { contentPage, products: {}, productsByCategory: {} };
  }

  const categoriesToFetch: Record<string, string> = {};

  const skusToFetch: Record<string, string> = {};

  for (const slot of contentPage.fields.slots) {
    if (slot.sys.contentType) {
      const type = slot.sys.contentType.sys.id;

      if (type === 'productGrid') {
        const categoryKey = (slot as IProductGrid).fields.categoryKey;

        categoriesToFetch[categoryKey] = categoryKey;
      }

      if (type === 'outfit') {
        for (const sku of (slot as IOutfit).fields.productRail.fields.skus) {
          skusToFetch[sku] = sku;
        }
      }

      if (type === 'productMosaic') {
        for (const sku of (slot as IProductMosaic).fields.skus) {
          skusToFetch[sku] = sku;
        }
      }

      if (type === 'productRail') {
        for (const sku of (slot as IProductRail).fields.skus) {
          skusToFetch[sku] = sku;
        }
      }
    }
  }

  const productProjectionsByCategory = await searchByCategories({
    keys: Object.values(categoriesToFetch),
    locale,
  });

  const productProjectionsBySku = await searchBySkus({
    skus: Object.values(skusToFetch),
    exclude: Object.keys(productProjectionsByCategory),
    locale,
    allowOutOfStock: true,
  });

  const products = {
    ...productProjectionsByCategory,
    ...productProjectionsBySku,
  };

  const productsByCategory = assignProductKeysToCategories(
    productProjectionsByCategory,
    locale
  );

  return { contentPage, products, productsByCategory };
};

export default fetchContentAndProducts;
