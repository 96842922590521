import { IPasswordProtectedRoutes } from 'generated/contentful';
import { logApiError } from 'lib/apiLogger';
import fetchResource from 'lib/contentful/fetchResource';
import { UNRESTRICTED_COOKIE } from 'lib/restrictedRoutes';
import { decrypt, encrypt } from 'lib/restrictedRoutes/encryption';
import { removeRouteLocale } from 'lib/restrictedRoutes/routes';
import { unique } from 'lib/util';
import { NextApiHandler } from 'next';

export interface Success {
  success: true;
  [UNRESTRICTED_COOKIE]: string;
  message?: undefined;
}

export interface Failure {
  success: false;
  [UNRESTRICTED_COOKIE]?: undefined;
  message: string;
}

export type ValidateRestrictedRoutePasswordResponse = Success | Failure;

export const validateRestrictedRoutePassword: NextApiHandler<
  ValidateRestrictedRoutePasswordResponse
> = async ({ method, cookies, body }, res) => {
  if (method !== 'POST') {
    res.setHeader('Allow', ['POST']);

    return res
      .status(405)
      .json({ success: false, message: `Method ${method} not allowed` });
  }

  const { password, route } = body;

  if (typeof route !== 'string') {
    return res.status(400).json({ success: false, message: 'Missing route' });
  }

  if (typeof password !== 'string') {
    return res
      .status(400)
      .json({ success: false, message: 'Missing password' });
  }

  try {
    const uri = removeRouteLocale(route);

    const passwordProtectedRoutes = await fetchResource({
      type: 'passwordProtectedRoutes',
      limit: 100,
      query: {
        password,
      },
    });

    const routesVisibleToCustomer =
      decrypt<string[]>(cookies[UNRESTRICTED_COOKIE]) ?? [];

    const routesProtectedByEnteredPassword = passwordProtectedRoutes.flatMap(
      p => (p as IPasswordProtectedRoutes).fields.protectedRoutes
    );

    if (routesProtectedByEnteredPassword.includes(uri)) {
      const allRoutesVisibleToCustomer = routesVisibleToCustomer.concat(
        routesProtectedByEnteredPassword
      );

      return res.status(200).json({
        success: true,
        [UNRESTRICTED_COOKIE]: encrypt(unique(allRoutesVisibleToCustomer)),
      });
    } else {
      return res.status(401).json({
        success: false,
        message: 'Password is incorrect. Please try again',
      });
    }
  } catch (e) {
    logApiError(e);

    return res.status(500).json({
      success: false,
      message: 'Unfortunately, we were unable to fulfil your request',
    });
  }
};
