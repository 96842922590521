import { IPasswordProtectedRoutes } from 'generated/contentful';
import fetchResource from 'lib/contentful/fetchResource';
import { unique } from 'lib/util';

export const retrieveWhetherRouteIsRestricted = async (
  route: string
): Promise<boolean> => {
  const passwordProtectedRoutes = await fetchResource<IPasswordProtectedRoutes>(
    {
      type: 'passwordProtectedRoutes',
      limit: 1,
      query: {
        protectedRoutes: route,
      },
    }
  );

  return passwordProtectedRoutes.length > 0;
};

export const retrieveRoutesProtectedByPassword = async (
  password: string
): Promise<string[]> => {
  const passwordProtectedRoutes = await fetchResource<IPasswordProtectedRoutes>(
    {
      type: 'passwordProtectedRoutes',
      limit: 1000,
      query: {
        password,
      },
    }
  );

  return unique(passwordProtectedRoutes.flatMap(p => p.fields.protectedRoutes));
};
