import AgsForm from 'components/AgsForm';
import { PageProps } from 'components/App';
import ContentPageSlots from 'components/ContentPageSlots';
import DbsCompetitionForm from 'components/DbsCompetitionForm';
import FlashBanner from 'components/FlashBanner';
import Footer from 'components/Footer';
import Gap from 'components/Gap';
import Header from 'components/Header';
import MetaHead from 'components/MetaHead';
import Nav from 'components/Nav';
import { ContentPageModel } from 'models/contentPages/types';
import { MediaSourceModel } from 'models/contentful/media/types';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { FC } from 'react';
import styled, { css } from 'styled-components';

const Wrapper = styled.div(
  ({ theme: { colors } }) => css`
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: ${colors.white};
  `
);

const OverlayContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContentContainer = styled.div<
  Pick<ContentPageModel, 'displayMode'>
>(({ displayMode }) =>
  displayMode === 'overlay'
    ? css`
        position: relative;
        margin-top: -80px;
      `
    : ``
);

const ContentPage: FC<
  PageProps<ContentPageModel> & { locale?: string; origin?: string }
> = ({
  header,
  browserTitle,
  metaTitle,
  metaDescription,
  metaImage,
  displayMode,
  darkMode,
  slots,
  footer,
  siteSettings,
  locale: l,
  origin,
}) => {
  const { asPath, query } = useRouter();

  const locale = l ?? (query.locale as string);

  const pagePath: string | undefined = asPath.split('/')[2];

  let imagesToPreload: string[] = [];
  if (slots[0] && slots[0].blockType === 'Hero') {
    imagesToPreload = Object.values(slots[0].storySource.media).reduce(
      (acc: string[], value) => {
        if (value) {
          const mediaSource = value as unknown as MediaSourceModel;
          acc.push(mediaSource.image.url);
        }
        return acc;
      },
      []
    );
  }

  return (
    <>
      <Head>
        {origin ? <link rel="canonical" href={`${origin}${asPath}`} /> : null}
        {imagesToPreload.map(url => (
          <link key={url} rel="preload" href={url} as="image" />
        ))}
      </Head>
      <Wrapper>
        <MetaHead
          browserTitle={browserTitle}
          metaTitle={metaTitle}
          metaDescription={metaDescription}
          metaImage={metaImage}
        />
        <FlashBanner flashBanner={siteSettings?.flashBanner} />
        <OverlayContainer>
          <Nav
            dark={displayMode === 'overlay' && darkMode}
            overlay={displayMode === 'overlay'}
            locale={locale}
          />
          <ContentContainer displayMode={displayMode}>
            {header ? (
              <>
                <Gap size={48} />
                <Header
                  {...header}
                  headerLevel="h1"
                  headerVariant="2"
                  truncate
                />
                <Gap size={48} />
              </>
            ) : null}
            <main id="main-content" tabIndex={-1}>
              <ContentPageSlots slots={slots} displayMode={displayMode} />
              {/* TODO: Remove when the CMS can handle this */}
              <AgsForm visible={pagePath?.startsWith('perfect-moment-ags')} />
              <DbsCompetitionForm
                visible={pagePath?.startsWith('dbs-competition')}
              />
              <Gap size={{ xs: 64, l: 96 }} />
            </main>
          </ContentContainer>
          <Footer
            footer={footer}
            paymentMethods={siteSettings?.paymentMethods}
            locale={locale}
          />
        </OverlayContainer>
      </Wrapper>
    </>
  );
};

export default ContentPage;
