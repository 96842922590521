import CampaignForm from 'components/CampaignForm';
import {
  defaultOnSubmitCampaignForm,
  formatCampaignCheckboxValue,
  parseCampaignCheckboxValue,
} from 'components/CampaignForm/CampaignForm';
import Gap from 'components/Gap';
import { CheckboxInput } from 'components/Input';
import { FC, useCallback } from 'react';
import { Field } from 'react-final-form';
import { useDispatch, useSelector } from 'store/hooks';

interface DbsCompetitionFormProps {
  visible?: boolean;
}

const DbsCompetitionForm: FC<DbsCompetitionFormProps> = ({ visible }) => {
  const dispatch = useDispatch();

  const ue = useSelector(state => state.customer.email);

  const onSubmit = useCallback(defaultOnSubmitCampaignForm(dispatch), [
    dispatch,
  ]);

  if (!visible) {
    return null;
  }

  return (
    <>
      <Gap size={{ xs: 64, l: 96 }} />
      <CampaignForm
        initialValues={{
          ue,
          pm_form: 'dbs_competition_june22',
          bs_optin: '0',
        }}
        inline={true}
        onSubmit={onSubmit}
        showNameFields={true}
      >
        <Field<string, HTMLInputElement, boolean>
          name="bs_optin"
          type="checkbox"
          parse={parseCampaignCheckboxValue}
          format={formatCampaignCheckboxValue}
        >
          {({ input, meta }) => (
            <CheckboxInput
              value="1"
              checked={input.checked}
              error={meta.error}
              label={
                <>
                  By providing my email address I agree to receive emails from
                  Dr. Barbara Sturm. I understand that I can withdraw consent at
                  any time. For more information view Dr. Barbara Sturm’s{' '}
                  <a
                    href="https://en.drsturm.com/privacy-policy/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </a>
                  .
                </>
              }
              name={input.name}
              onChange={input.onChange}
              showError={meta.touched}
            />
          )}
        </Field>
      </CampaignForm>
    </>
  );
};

export default DbsCompetitionForm;
