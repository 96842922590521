import { pipe } from 'fp-ts/function';
import { fold } from 'fp-ts/Either';
import { reportTypeErrors } from 'lib/reportTypeErrors';
import returnValidModel from 'lib/returnValidModel';
import { LocaleModel, localeModel } from 'models/locales/types';

// TODO add test that empty and missing locales do not report, but others do

const toLocale = <F extends LocaleModel | null = null>(
  input: string | string[] | undefined,
  fallback: F = null as F
): LocaleModel | F => {
  if (!input) {
    return fallback as F;
  }

  const locale = typeof input == 'string' ? input.toLowerCase() : input;

  return pipe(
    localeModel.decode(locale),
    fold(
      reportTypeErrors({
        id: `${JSON.stringify(input)}`,
        model: 'Locale',
        fallback: fallback as F,
      }),
      returnValidModel as () => LocaleModel | F
    )
  );
};

export default toLocale;
